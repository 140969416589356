import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const SomeDirPage = () => (
  <Layout>
    <SEO title="Page in Some Dir" />
    <h1>Hi from the some dir page</h1>
    <p>Welcome to some dir page</p>
    <Link to="/some-dir/page-4">Go back to page 4</Link>
  </Layout>
)

export default SomeDirPage
